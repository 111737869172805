<template>
    <div class="text-left mt-5 d-flex">
        <DatePicker v-model="range" is-range>
            <template v-slot="{ inputValue, inputEvents }">
                <div class="flex justify-center items-center">
                    <input
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                        class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                    />
                    -
                    <input
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                        class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                    />
                </div>
            </template>
        </DatePicker>

        <button @click="submitChangeDates(range)" type="button" class="base-btn">Apply</button>
    </div>
</template>

<script>
import { Calendar, DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";

export default {
    components: {
        Calendar,
        DatePicker,
    },
    props: {
        dates: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            range: {
                start: new Date(this.dates[0]),
                end: new Date(this.dates[1]),
            },
        };
    },
    methods: {
        submitChangeDates(range) {
            Livewire.emit("graphChangeDates", range);
        },
    }
};
</script>

<style lang="scss" scoped></style>
