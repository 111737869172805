<template>
    <div class="profit-calculator d-flex gap-4 align-items-end flex-column flex-md-row">
        <div>
            <div class="form-group mb-2">
                <label for="investment">Your investment</label>
                <input :value="formattedInvestmentInput" @change="handleInvestmentInput($event)"
                       type="text"
                       id="investment"
                />
            </div>

            <div class="form-group">
                <label for="months">How long you want to hold your invest</label>
                <input :value="formattedMonthsInput" @change="handleMonthsInput($event)" type="text" id="months" />
            </div>
        </div>

        <div class="estimate-profit d-flex align-items-center">
            <div>
                <small>Your estimate profit</small>
                <strong>{{ profitAnimation.toFixed(2) }} USDT</strong>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';

export default {
    name: "ProfitCalculator",
    data() {
        return {
            investmentSum: 5000,
            months: 24,
            profit: 0,
            profitAnimation: 0,
            returnRate: 5,
        }
    },
    created() {
        this.calculateProfit();
    },
    watch: {
        profit(n) {
            gsap.to(this, { duration: 0.5, profitAnimation: Number(n) || 0 })
        }
    },
    methods: {
        handleInvestmentInput(event) {
            this.investmentSum = parseFloat(event.target.value.replace(/,/g, '.'));
            // this.investmentSum = +event.target.value.replace(/\D/g,'');
            this.calculateProfit();
        },
        handleMonthsInput(event) {
            this.months = +event.target.value.replace(/\D/g,'');
            this.calculateProfit();
        },
        calculateProfit() {
            let total = parseFloat(this.investmentSum);
            let profit = 0;
            let months = parseInt(this.months);
            let returnRate = parseFloat(this.returnRate);

            for (let i = 1; i <= months; i++) {
                total = (total * ((100 + returnRate) / 100));
            }

            this.profit = total;
            // this.profit = profit.toFixed(4);
        }
    },

    computed: {
        formattedInvestmentInput() {
            return this.investmentSum + ' USDT';
        },
        formattedMonthsInput() {
            return this.months + ' months';
        },
    }


}
</script>

<style lang="scss" scoped>
@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/functions';

.profit-calculator {
    max-width: 870px;
    margin: 30px auto;
    .estimate-profit {
        background-color: $primary-color;
        border-radius: 8px;
        height: 159px;
        box-shadow: 0 1px 3px 0 rgba($black,.1);
        padding: 0 32px;
        small {
            display: block;
            font-weight: 500;
            font-size: rem(14px);
            color: rgba($white,.5);
        }

        strong {
            display: block;
            font-weight: 600;
            font-size: rem(38px);
            color: $white;
            line-height: 1;
            margin-top: 5px;
        }

        span {
            color: $white;
            font-weight: 600;
            font-size: rem(14px);
        }
    }
    label {
        font-size: rem(14px);
        color: #374151;
        font-weight: 500;
    }
    input {
        width: 100%;
        height: 60px;
        border: 1px solid #D1D5DB;
        outline: none;
        padding: 0 20px;
        border-radius: 6px;
        margin-top: 5px;
        box-shadow: 0 1px 2px 0 rgba($black,.25);
        font-size: rem(14px);
        color: #6B7280;
        &:focus {
            border-color: $primary-color;
        }
    }
    & >div {
        width: 100%;
        @media (min-width: 768px) {
            width: 50%;
        }
    }
}
</style>
