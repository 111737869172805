import { createApp } from "vue";

// Vue components
import ProfitCalculator from "./vue-components/ProfitCalculator";
import TradingChart from "./vue-components/TradingChart";
import FilterChartData from "./vue-components/FilterChartData";

const calculateProfitApp = createApp({});
const tradingChartApp = createApp({});
const filterChartDataApp = createApp({});

calculateProfitApp.component("profit-calculator", ProfitCalculator);
tradingChartApp.component("trading-chart", TradingChart);
filterChartDataApp.component("filter-chart-data", FilterChartData);

calculateProfitApp.mount("#calculate-profit-app");
tradingChartApp.mount("#trading-chart-app");
filterChartDataApp.mount("#filter-chart-data-app");
