<template>
    <Line
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
    />
</template>

<script>
import { Line } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
);

export default {
    name: "BarChart",
    components: { Line },
    props: {
        data: {
            type: Array,
            required: true
        },

        chartId: {
            type: String,
            default: "bar-chart",
        },

        aspectRatio: {
            type: Number,
        },
    },
    data() {
        console.log(this.data);
        return {
            chartData: {
                labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ],
                pointStyle: "circle",
                datasets: [
                    {
                        label: "Data one",
                        // data: [122, 125, 95, 150, 175],
                        data: [
                            { x: 0, y: 122 },
                            { x: 1, y: 125 },
                            { x: 2, y: 95 },
                            { x: 3, y: 150 },
                            { x: 4, y: 175 }
                        ],
                        borderColor: "#0B4BFE",
                        backgroundColor: "rgba(11,75,254,.5)",
                        pointRadius: 10,
                        pointHoverRadius: 15,
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                aspectRatio: this.aspectRatio,
            },
        };
    },
};
</script>
